$border: 1px solid #e8e8e8;

.item {
    padding: 0;
    border: none;
    border-bottom: $border;
    display: flex;
    background: #fff;
    transition: all linear 200ms;
    align-items: center;
    justify-content: flex-start;
    height: 79px;
    width: 100%;
    font-size: 16px;
    text-align: left;
    color: #333;
    cursor: pointer;

    &:active {
        background: #ddd;
    }

    &:focus {
        outline: none;
    }

    span {
        flex-grow: 1;
    }

    svg {
        width: 24px;
        margin-right: 5px;
    }
}

.submitting {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sectionName {
    padding: 50px 0 20px 0;
    margin: 0;
    border-bottom: $border;

    &:first-child {
        padding-top: 30px;
    }
}

.buttonGroup{
    display: flex;
    gap: 30px;
}

.checkbox{
    display: flex;
    gap: 5px;
}

.permissionText {
    font-size: 16px;
    font-weight: 400;
    color: #1E3E5F;
}

.permissionDesc {
    font-size: 19px;
    font-weight: 500;
    line-height: 23px;
    margin: 30px 0;
    color: #323232
}

.permissionDetail {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.permissionButton{
    display: flex;
    gap: 30px;

    > button {
        margin-top: 30px;
    }
}

.permissionHeading {
    color: #323232;
    font-weight: 500;
    font-size: 19px;
}

.retryIns {
    font-weight: 600;
}

.contactSupport {
    line-height: 50px;
}