.normal {
    margin-bottom: 25px;

    .slide {
        height: 220px;
        overflow: hidden;
        position: relative;
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.slide {
    outline: 0;
    position: relative;

    img {
        width: 100%;
    }

    .viewLarge {
        position: absolute;
        right: 20px;
        bottom: 20px;
        font-size: 14px;
        height: 26px;
    }
}

.placeholder {
    height: 220px;
    background-color: #3b495c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
        height: 128px;
        width: 208px;
        border: 2px dashed #5f7f99;
        border-radius: 5px;
        background-color: #415166;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > span {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #c6d5de;
        }
    }
}

.trash {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}

// overwrite dots styling from 'slick-carousel'
:global {
    .slick-dots {
        bottom: 15px;
        li {
            margin: 0;
            button:before {
                font-size: 10px;
                opacity: 0.5;
                color: #fff;
            }
            &.slick-active button:before {
                opacity: 1;
                color: #fff;
            }
        }
    }
}
