* {
    box-sizing: border-box;
}

html, body {
    background: #1e3e5f;
    overflow: hidden;
}

.splash-page {
    background-image: url(./components/icons/splash_bg.svg);
    background-size: cover;
    /*background-color: #1e3e5f;*/
    background-position: 50% 0%;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    overflow: auto;
    padding-top: 60px;
}
    .splash-page .splash-spacer {
        display: flex;
        background: none;
        flex: 1;
        align-items: center;
        justify-content: center;
    }

    .splash-page .splash-content {
        background-color: #fff;
        overflow: hidden;
    }

        .splash-page .splash-content.transparent {
            background: none;
        }

.form {
    padding: 20px;
}
    .form input, form select {
        font-size: 16px;
        border: none;
        border-bottom: 1px solid rgba(0,0,0,0.2);
        /*height: 50px;*/
        padding: 16px 0;
        width: 100%;
        margin-bottom: 20px;
    }

    .form input:focus, .form select:focus, .form input:active, .form select:active {
        border-bottom-color: #1cde99;
        outline: none;
        background: #1e3e5f08;
    }

    .form input.error {
        border-bottom-color: #db0000;
    }

    .form .button {
        width: 100%;
    }

    .button[type='submit'], .button.submit {
        background-color: #1cde99;
        color: #000;
    }

    .button {
        height: 50px;
        font-size: 18px;
        border-radius: 50px;
        border: none;
        background: #eee;
        transition: opacity ease-in 500ms;
        font-weight: 600;
    }

    .button.primary {
        color: #fff;
        background-color: #1f3d5e;
    }

    .button.default {
        background-color: #fff;
        border: 1px solid #1f3d5e;
        color: #1f3d5e;
    }

    .link-button {
        border: none;
        background: none;
        text-decoration: underline;
        color: #1f3d5e;
        font-size: 18px;
    }

    .button:disabled, .link-button:disabled {
        opacity: 0.3;
    }

@media (min-width: 680px) {
    footer > .form {
        width: 528px;
        margin-left: auto;
        margin-right: auto;
    }

    .splash-content {
        width: 528px;
        align-self: center;
        margin: 0 auto 80px;
        border-radius: 8px;
    }
}
