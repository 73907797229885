.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    > img {
        width: 80px;
        height: 80px;
        margin: 15px 0 15px;
    }

    .disclaimer {
        margin: 0;
        color: #1e3e5f;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
}

.sigTop {
    width: 100%;
    max-width: 600px;
    margin: 30px 0 15px;
    display: flex;
    justify-content: space-between;

    label {
        font-size: 14px;
        color: #767676;
    }

    button {
        border: 0;
        padding: 0;
        background: none;
        font-size: 14px;
        line-height: 0;
        color: #1e3e5f;
        text-decoration: underline;
    }
}

.box {
    margin: 0;
    background-color: transparent;
    height: 76px;
    width: 271px;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}

.button {
    width: 100%;
}

.center {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.sigBox {
    line-height: 0;
    border: 1px dashed #cdcdcd;
}
