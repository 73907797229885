@import 'styles/variables';

.button {
  width: 100%;
  height: 50px;
  margin-top: 80px; // including this since it's applicable to most use-cases
  padding: 0;
  border: 0;
  border-radius: 25px;
  outline: 0;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity ease 250ms;

  &:disabled {
    cursor: auto;
    opacity: 0.3;
  }

  &.blue {
    color: #fff;
    background: $blue;
  }

  &.green {
    color: #000;
    background: $green;
  }

  &.outline {
    color: $blue;
    background: #fff;
    border: 1px solid $blue;
  }
}
