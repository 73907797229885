.push-enter {
  transform: translateX(100%);
}

.push-enter-active {
  transform: translateX(0);
  transition: all 400ms;
  transition-delay: 200ms;
}

.push-exit {
  transform: translateX(0);
}

.push-exit-active {
  transform: translateX(-100%);
  transition: all 400ms;
  transition-delay: 200ms;
}

.pop-enter {
  transform: translateX(-100%);
}

.pop-enter-active {
  transform: translateX(0);
  transition: all 400ms;
}

.pop-exit {
  transform: translateX(0);
}

.pop-exit-active {
  transform: translateX(100%);
  transition: all 400ms;
}
