.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    background: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    .button {
        background: none;
        border: none;
        color: #fff;
        display: block;
        width: 80px;
        font-size: 16px;
    }
}