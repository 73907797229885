.instruction {
    margin: 0 0 30px 0;
    padding: 0;
    color: #5a5a5a;
    font-size: 14px;
    line-height: 16px;
}

.label {
    > label {
        color: #5a5a5a;
        font-size: 14px;
        display: block;
        padding-bottom: 4px;
    }
}
