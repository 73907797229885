.doubleInputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    width: calc(50% - 10px);
  }
}

.tripleInputs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    width: calc(33% - 10px);
  }
}

.label {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
}

.note {
  margin: 0 0 18px;
  font-size: 14px;
  line-height: 22px;
  color: #5f7f99;
}
