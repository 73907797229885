.container {
    // TODO: margin should really be padding on parent
    margin: 0 20px 30px 20px;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    mask-image: radial-gradient(#fff, #000); // hack/fix for safari border-radius + overflow: hidden

    @media (min-width: 768px) {
        margin: 0 auto 40px auto;
        max-width: 708px;
    }

    &.limit {
        height: 280px; // 3.5 items height
    }
}

.list {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; // TODO: without this a scrollbar will pop up for whatever reason when selecting a task

    button {
        background-color: #fff;
        width: 100%;
        height: 80px;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        color: #000;
        padding: 0 20px;
        border: none;
        border-bottom: 1px solid #eee;
        outline: none;
        transition: all linear 200ms;
        cursor: pointer;

        &:active {
            background-color: #ddd;
        }

        img.icon {
            width: 36px;
            margin-right: 20px;
        }

        svg {
            position: absolute;
            right: 20px;
            width: 24px;
        }

        &:last-child {
            border-bottom: none;
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.4);
            cursor: auto;
            img, svg {
                opacity: 0.4;
            }
        }
    }
}

.permissionText {
    font-size: 16px;
    font-weight: 400;
    color: #1E3E5F;
}
