.main {
    overflow-y: auto;
}

.centerBorder {
    color: #1BDD9A;
    margin-right: 10px;
}

.text {
    font-weight: 400;
    font-size: 16px;
    line-height: 190.5%;
    color: #1BDD9A;
}

.topContent {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.heading {
    display: flex;
    flex-direction: column;

    h1 {
        font-weight: 400;
        font-size: 46px;
        line-height: 58px;
        color: #FFFFFF;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .coloredWord {
        margin: 0px;
        padding: 0px;
        background: -webkit-linear-gradient(#79D7F5, #1BDD9A);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    label {
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
}

.caption {
    display: flex;
    margin-top: 0px;
}

.smartTasks {
    padding: 20px 24px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 141.4%;
        color: #1D3C5E;
    }
}

.completeTaskText {
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.spacing {
    margin: 40px 60px;

    @media (max-width: 528px) {
        margin: 50px 20px;
    }

    >p {
        font-size: 14px;
        font-weight: 500;
        color: #1d3c5e;
    }

    a {
        color: inherit;
    }
}