@import 'styles/variables';

.page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.nav {
  height: 50px;
  background: #f9f9f9;
  border-bottom: 1px solid #cdcdcd;
  flex: 0 0 50px;

  @media (min-width: $breakpoint) {
    height: 60px;
    flex: 0 0 60px;
  }
}

.navContent {
  max-width: $content;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #030303;
    max-width: calc(100% - 160px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  button {
    padding: 6px;
    border: 0;
    background: transparent;
    font-size: 16px;
    color: #484d60;
    cursor: pointer;

    &.back {
      position: absolute;
      left: 9px;
      padding-left: 0; // offset internal svg padding
      display: flex;
      align-items: center;
      svg {
        width: 24px;
        height: 24px;
      }

      @media (min-width: $breakpoint) {
        left: -6px;
      }
    }
  }
}

// wrapper is used to keep the scroll bar at the edge
.contentWrapper {
  flex: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1 0 auto;
    width: 100%;
    max-width: $content;
    margin: 0 auto;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
      padding: 30px 0 80px;
    }
  }
}

.main {
  flex: auto;

  &.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
