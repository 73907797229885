.tile {
  width: 100%;
  height: 200px;
  margin: 10px 0;
  border-radius: 3px;
  border: 1px dashed #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin: 0 0 15px;
    color: #333;
  }
}

.tile.previewImage {
  border: none;
  background: #f5f5f5;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  button.delete {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
}

.page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9990;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    margin: 0;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
  }
}

.preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .error {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 18px 22px;
    color: #fff;
    line-height: 19px;
    background: #c94444;
    display: flex;
    img {
      margin-right: 15px;
    }
    p {
      margin: 0;
    }
  }

  video {
    object-fit: cover;
  }

  .previewFrame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    font-size: 86px;
    font-weight: bold;
    color: #fff;
  }

  canvas {
    display: none;
  }

  .message {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 40px 30px;
    text-align: center;
    color: #fff;
    line-height: 19px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    p {
      margin: 0;
    }
  }
}

.controls {
  height: 135px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  > button {
    height: 64px;
    width: 64px;
    padding: 0;
    transition: opacity ease-in 100ms;
    outline: none;
    &:disabled {
      opacity: 0.8;
    }
  }

  .cancel {
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    background: none;
    border: none;
    outline: none;
  }

  .snap,
  .switch {
    background: none;
    border: none;
    outline: none;
  }

  .back,
  .confirm {
    border: none;
    border-radius: 50%;
  }

  .back {
    background: #fff;
  }

  .confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1bdd9a;
  }
}
