@import 'styles/variables';

// position hardcoded
.guide {
  position: absolute;
  top: 50px;
  bottom: 135px;
  left: 0;
  right: 0;
  > div {
    height: 100%;
    max-width: $content;
    padding: 20px;
  }

  @media (min-width: $breakpoint) {
    top: 60px;
    bottom: 175px;
    > div {
      margin: 0 auto;
      padding: 20px 0;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.15);

  > div {
    max-width: $content;
    margin: 20px;

    @media (min-width: $breakpoint) {
      margin: 40px auto;
    }
  }
}

button.cannotFind {
  width: 100%;
  height: 30px;
  margin-top: 15px;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  font-size: 16px;
  color: $blue;
  text-decoration: underline;
}
