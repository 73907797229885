.yesno {
    display: block;


    .content {
        @media (min-width: 680px) {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > p {
                font-size: 18px;
                margin: 0;
                flex: 1;
                padding-right: 40px;
            }

            .toggle {
                margin-bottom: 0;
                width: 165px;
                flex: 0 0 165px;
            }
        }
    }
}

.hint {
    margin: 18px 0 -5px 0;
    padding: 0;
    color: #767676;
    font-size: 14px;
    line-height: 16px;
}

.toggle {
    margin-bottom: 15px;
}
