.image-slide-searcher {
    display: flex;
    flex-direction: column;
    height: 100%;

    .image-container {
        scroll-snap-type: x mandatory;
        overflow-x: scroll;
        display: flex;

        > img {
            width: 100%;
            object-fit: contain;
            scroll-snap-align: center;
            scroll-snap-stop: always;
        }
    }

    .image-label-container {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #5e8099;
        font-weight: 500;
        width: 100%;
        align-items: center;

        > button {
            background: none;
            border: none;
            color: #5e8099;
            font-weight: 500;
            padding: 8px 10px;
            font-size: 22px;
        }

        > label {
            padding: 8px 0;
            display: block;
        }

        > button:disabled {
            opacity: 0.5;
        }
    }
}
