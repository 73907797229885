.finish {
  transform: translateY(100%);
}

.finishShow {
  transform: translateY(0);
  transition: transform 400ms;
}

.taskPermission {
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0;
}

.taskNote {
  @extend .taskPermission;
  font-weight: 700;
}