.toggle {
    display: flex;
    background-color: #F6F6F6;
    color: #000;
    border-radius: 30px;

    .option {
        padding: 10px;
        margin: 4px;
        flex: 1 1 auto;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        transition: background-color ease 300ms;

        &.active {
            background-color: #1cde99;
            color: #000;
        }

        &:focus {
            background: none;
            outline: none;
        }

        &.disabled {
            pointer-events: none;
        }
    }
}
