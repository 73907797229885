.spinner {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 3.75em;
    height: 3.75em;
    animation: rotate 2s linear infinite;

    circle {
      fill: none;
      // stroke: #1cde99;
      stroke-width: 3.5;
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  &.small {
    width: 35px;
    height: 35px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
}
