.tnc {
  display: block;
  position: relative;
  padding-top: 3px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #1d3c5e;
  user-select: none;

  // hide default checkbox
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ span:after {
      display: block;
    }
  }

  // custom checkbox
  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border: 1px solid #1d3c5e;
    border-radius: 3px;

    &:after {
      display: none;
      content: '';
      position: absolute;
      top: 3px;
      left: 7px;
      width: 5px;
      height: 10px;
      border: solid #1d3c5e;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  a {
    color: inherit;
  }
}

.error {
  height: 64px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #db0000;

  button {
    margin: -8px 16px 0 16px;
    padding: 0;
    color: #fff;
    font-size: 40px;
    font-weight: 100;
    background: none;
    border: none;
  }
}

.spacing {
  margin: 40px 60px;

  @media (max-width: 528px) {
    margin: 30px 20px;
  }

  > p {
    font-size: 14px;
    font-weight: 500;
    color: #1d3c5e;
  }

  a {
    color: inherit;
  }
}

.inputs {
  margin-bottom: 25px;
}

p.line2 {
  margin-top: 20px;
  color: #767676;
  a {
    color: #1d3c5e;
  }
}
