.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: center;
    color: #1f3d5e;

    .content {
        .main {
            margin-top: 50px;
            margin-bottom: 40px;

            .vin {
                font-size: 28px;
                margin: 15px 0;
                font-weight: bold;
            }
        }

        hr {
            margin-top: 20px;
            margin-bottom: 25px;
            border: 0;
            border-top: 1px solid #d9d9d9;
        }

        h4 {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
        }

        p {
            padding: 0 25px;
            font-size: 14px;
            line-height: 22px;
        }
    }

    footer {
        padding: 20px;

        button {
            width: 100%;
        }
    }
}
