.info {
  text-align: center;

  img {
    width: 140px;
    height: 140px;
  }

  p {
    line-height: 24px;
    color: #003f6c;
  }
}
