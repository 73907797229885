.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tile {
  width: calc(50% - 10px);
  height: 200px;
  margin: 10px 0;
  border-radius: 3px;

  &:only-child {
    width: 100%;
  }
}

.add {
  border: 1px dashed #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    margin: 0 0 15px;
    color: #333;
  }

  small {
    margin: 0 0 10px;
    color: #888;
  }
}

.pointer {
  cursor: pointer;
}

.image {
  background: #f5f5f5;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  button.delete {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
}

.instruction {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 16px;
  color: #5a5a5a;
}

.name {
  margin: 0 0 20px;
  font-size: 18px;
  line-height: 16px;
  color: #5a5a5a;
  font-weight: 500;
}

p.error {
  margin: 10px 0 0;
  font-size: 14px;
  line-height: 16px;
  color: #db0000;
}

.disabled{
  pointer-events: none;
}
