.icon {
  display: flex;
  justify-content: center;
  position: relative;

  .line {
    position: absolute;
    top: 6px;
    animation: scan 1.5s linear infinite;
  }

  .success {
    width: 63px;
    height: 63px;
  }
}

.text {
  margin: 60px 0;
  text-align: center;
}

@keyframes scan {
  0% {
    top: 6px;
    opacity: 20%;
  }
  25% {
    opacity: 100%;
  }
  50% {
    top: 54px;
    opacity: 20%;
  }
  75% {
    opacity: 100%;
  }
  100% {
    top: 6px;
    opacity: 20%;
  }
}
