.container {
  margin-bottom: 25px;

  label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #5a5a5a;
  }

  input,
  select {
    appearance: none;
    width: 100%;
    height: 46px;
    border: 0;
    border-bottom: 1px solid #d8d8d8;
    border-radius: 0;
    outline: 0;
    background: none;
    font-family: inherit;
    font-size: 18px;
    line-height: 21px;

    &:focus {
      background: #f7f7f7;
      border-color: #1cde99;
    }
  }

  select {
    &:invalid {
      color: #cdcdcd;
    }

    // assuming the placeholder is always first in the list
    option:not(:first-of-type) {
      color: #000;
    }
  }
}
