@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,bold,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin: 30px 0;
  border: 0;
  border-top: 1px solid #d8d8d8;
}
