.camera {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9990;
    background: #000;
    display: flex;
    flex-direction: column;
}

    .camera > header {
        color: #fff;
        display: block;
        text-align: center;
        padding: 20px;
        font-size: 18px;
    }

    .camera .body {
        flex: 1;
    }

        .camera .body > .camera-viewport, .camera .body > .camera-output {
            width: 100vw;
            max-height: 100%;
            padding: 0;
            margin: 0;
        }

    .camera > footer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        position: fixed;
        bottom: 30px;
        left: 0;
        right: 0;
        z-index: 9999;
    }

        .camera > footer button {
            display: block;
        }

    .camera button.snap {
        background: #fff;
        border-radius: 64px;
        font-size: 1px;
        width: 64px;
        height: 64px;
        position: relative;
        border: none;
        margin: 0 40px;
    }

        .camera button.snap:before {
            content: "";
            background-color: #fff;
            transition: background-color linear 150ms;
            border: 1px solid #555;
            position: absolute;
            border-radius: 50px;
            left: 7px;
            top: 7px;
            right: 7px;
            bottom: 7px;
        }

        .camera button.snap:active:before {
            background-color: #ddd;
        }

        .camera button.snap:focus {
            outline: none;
        }

    .post-options {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    .camera button.option {
        display: block;
        border: none;
        background: none;
        color: #fff;
        font-size: 17px;
        outline: none;
    }

    .camera button.option > img {
        height: 64px;
    }

    .camera .flash-button {
        width: 80px;
        text-align: center;
    }

    .camera .flash-button > img {
        width: 28px;
    }

    .camera button.option.option-switch {
        width: 80px;
        text-align: center;
    }
        .camera button.option.option-switch > img {
            width: 36px;
        }

.cancel {
    position: fixed;
    z-index: 9999999;
    top: 10px;
    left: 10px;
    font-size: 40px;
    line-height: 30px;
    color: #fff;
    border: none;
    padding: 4px;
    margin: 0;
    background: none;
    font-weight: 100;
    font-family: monospace;
    cursor: pointer;
}